@import "colors";
@import "variables";

@import "components/index";
@import "pages/index";
@import "ant-design-override/index";
@import "helpers/index";

body {
    background-color: $light-gray;
}

#root {
    height: 100%;
    background-color: $light-gray;
}

#root.root-dev {
    background-color: $background-dev;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='50px' width='120px'><text x='0' y='15' fill='%23a4a4a4' font-size='20'>development</text></svg>");
}

#root.root-staging {
    background-color: $background-staging;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='50px' width='120px'><text x='0' y='15' fill='%23a4a4a4' font-size='20'>staging</text></svg>");
}

.button-row {
    width: 100%;
    text-align: right;
}

.pointer {
    cursor: pointer;
}
