body {
    padding-top: $body-padding;
    @media (max-width: 767px) {
        padding-top: $navbar-height + 10px;
    }
}

.navbar {
    display: flex;
    justify-content: space-between;
    background: #f5f5f5;
    border-bottom: 1px solid #e6d8d8;
    margin-bottom: 10px;

    background: $primary-main;
    color: white;
    width: 100%;
    z-index: 100;

    .navbar-logo-container {
        width: 200px;
    }

    .navbar-logo {
        height: 35px;
    }

    @media (max-width: 590px) {
        padding: 0 15px;

        .navbar-logo {
            height: 31px;
        }
    }
}
