.container {
    width: 100%;
    padding-right: 24px;
    padding-left: 24px;
    margin: 24px auto;
    background-color: transparent;
    min-height: 280px;
}

.container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
