h1.ant-typography {
  font-size: 50px;
  font-weight: 300;
}

h4.ant-typography {
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5 {
  &.ant-typography {
    color: $gray;
  }
}
