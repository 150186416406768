.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  max-width: 330px;
  flex-direction: column;

  height: calc(100% - #{$body-padding});

  // medium -> tablet
  @media (min-width: 577px) and (max-width: 1200px) {
    max-width: 400px;
  }

  // small phone
  @media (max-width: 576px) {
    max-width: initial;

    h1.ant-typography {
      font-size: 40px;
    }
  }

  @media not all and (min-resolution:.001dpcm) { 
   margin-top: 60px;
}

  .ant-form {
    .ant-input {
      border-top: none;
      border-left: none;
      border-right: none;
      border-radius: 0;

      &:not(.ant-input-disabled) {
        &:focus,
        &:hover {
          box-shadow: none !important;
          border-color: $primary-main !important;
          color: $primary-main;
        }
      }
    }
  }

  .remember-me-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
}
