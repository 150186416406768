.ant-layout {
  background: transparent;
  height: 100%;
}

.ant-table-wrapper {
  @media (max-width: 767px) {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    border: 1px solid #e8e8e8;
    table {
      border: none;
    }
  }
}

.ant-list {
  .ant-list-header {
    background: #f3f3f3;
  }
}

.ant-layout-sider {
  background-color: #DBDDDE;
}

.ant-menu {
  background-color: #DBDDDE;

  .ant-menu-item-divider {
    background-color: $primary-alt;
    margin:0 20px;
    
  }
}

.anticon {
  font-size: 1.3em;
}


@import "header";
@import "button";
@import "title";
@import "form";
@import "calendar-picker";
@import "divider";
@import "dropdown";
@import "steps";
