// primary colors
$primary-main: #154062;
$primary-alt: #0c8aee;
$gray: #6d6e71;
$light-gray: #f4f4f4;

// text
$text-dark: rgba(0, 0, 0, 0.65);

// secondary colors
$secondary: #d6e9f1;
$secondary-pink: #facbc3;
$secondary-teal: #47a9c3;

// inactive state
$primary-inactive: rgba($primary-main, 0.3);

// background color
//$background-color: rgba($primary-alt, 0.05);

//ant colors
$ant-border-color: #d9d9d9;
$ant-table-border-color: #e8e8e8;

// background color
$background-dev: $light-gray;
$background-staging: $light-gray;
