// color for ant button glow effect around buttons
@mixin button-glow($glow-color) {
  &:after {
    border: 0px solid $glow-color;
  }
}

// override mixin for ant colors and states
@mixin button-style($background-color, $border-color, $text-color) {
  background: $background-color;
  border-color: $border-color;
  color: $text-color !important;

  &:hover,
  &:focus {
    background: $background-color;
    border-color: $border-color;
  }

  &:active {
    background: darken($background-color, 5%);
    border-color: $border-color;
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.25) !important;
  }
}

// ex: <Button type='primary'/>
.ant-btn {
  @include button-style($light-gray, $ant-table-border-color, $text-dark);
  @include button-glow($light-gray);
}

.ant-btn-primary {
  @include button-style($primary-alt, transparent, white);
  @include button-glow($primary-alt);
}

.ant-btn-primary-outline {
  @include button-style(transparent, $primary-alt, $primary-alt);
  @include button-glow($primary-alt);
}

.ant-btn-secondary {
  @include button-style($secondary-teal, transparent, white);
  @include button-glow($secondary-teal);
}

// link overrride
a {
  color: $primary-main;

  &:hover,
  &:focus {
    color: $primary-main;
  }
  &:active {
    color: darken($primary-main, 5%);
  }
}

a.active {
  color: $primary-main;
}
