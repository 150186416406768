.ant-calendar-picker {
  display: block;
  width: 90%;
}

.rc-calendar-input {
  pointer-events: none;
}

@media(max-width: 576px){
  .ant-calendar-input-wrap {
    display: none;
  }
}

