.tag-container {
  line-height: 1;
  margin-top: 5px;
  margin-bottom: 5px;

  .ant-tag {
    margin-top: 3px;
    margin-bottom: 3px;
    
    background: white;
    color: $secondary-teal;
    border-color: $secondary-teal;

    cursor: initial;
    user-select: none;
  }
}
